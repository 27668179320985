import { EventBus } from "~events"

export const getHandlers = key => {
    return document.querySelectorAll(`[data-${key}-handler]`)
}

export const handlerClickBehavior = (key, handler = null) => {
    EventBus.$emit(`${key}_OPEN`, handler)
}

export const clickEvents = ["click", "mousedown", "touchstart"]
